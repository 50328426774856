export const categories = [
    "Nursing", 
    "Midwifery",
    "Medical Laboratory Science", 
    "Radiology", 
    "Dentistry", 
    "Pharmacy", 
    "Physiotherapy",
    "Public Health"
]


export const categoriesData = [
    {
        caption: "Nursing",
        image: "/media/nurse-drip.jpg",
        category: "Nursing",
    },
    {
        caption: "Medical Lab. Science",
        image: "/media/mlt.jpeg",
        category:  "Medical Laboratory Science"
    },
    {
        caption: "Midwifery",
        image: "/media/midwifes.jpeg",
        category: "Midwifery"
    },
    {
        caption: "Radiology",
        image: "/media/radiology.jpeg",
        category: "Radiology"
    },
    {
        caption: "Pharmacy",
        image: "/media/pharmacy.jpg",
        category:  "Pharmacy"
    },
    {
        caption: "Dentistry",
        image: "/media/dentist.jpeg",
        category: "Dentistry"
    },
    {
        caption: "Public health",
        image: "/media/pub-health.jpeg",
        category: "Public Health"
    },
    {
        caption: "Physiotherapy",
        image: "/media/phisio.jpeg",
        category: "Physiotherapy"
    }
]