import  React, { useState } from "react";
import classNames from "classnames";
import { parseTime } from "../../helpers/parse-time";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import "../../styles/notes.css";
import "../../styles/search.css";
import "../../styles/main.css";

import { BmsHeader } from "../../components/main-components/app.header";
import { RightNav } from "../../components/main-components/right-nav";
import { getAllprojects } from "../../services/documents/documents.services";
import { Loader } from "../../components/utilities/loader";
import { AppFooter } from "../../components/main-components/footer";
import { useAuth } from "../../services/authentication/authentication.context";
import { AddButton } from "../../components/utilities/buttons";



export const ProjectsPage = () => {

    const { isAuthenticated } = useAuth();

    const navigateTo = useNavigate();

    const [projects, setProjects] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [loading, setLoading] = useState(false);
   
    const [keywrd, setKeywrd] = useState(null);
   
    const getAllResearchProjects = async (pageNum) => {
        try {
            setLoading(true);
            const response = await getAllprojects();
            const res = await response.json();
            const recents = res.slice((pageNum - 1) * 5, ((pageNum - 1) * 5) + 5);
            setProjects(recents);
            console.log(recents);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log({error: `Internal server error: ${e}`});
        }
    }

    const searchProjects = async (searchWord) => {
        setLoading(true);
        try {
            const response = await getAllprojects();
            const res = await response.json();
            var reg = new RegExp(searchWord.toLowerCase());
            let substr = searchWord.slice(0,3).toLowerCase();
            var subReg = new RegExp(substr);
            let results = res.filter((term) => {
                let wordStr = term.title.toLowerCase();
                
                return ( wordStr.match(reg) || wordStr.match(subReg)  ) ? term : null ;
            });
            setProjects(results);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log({error: `Internal server error: ${e}`});
        } 
    }

    React.useEffect(() => {
        const controller = new AbortController();
        ( async() => {
            await getAllResearchProjects(pageNum);
        })();
        ( () => controller.abort() )();
    }, [pageNum]);
    
    

    return (
        <div className="page-body">
            <BmsHeader />
            <div 
                className={classNames(
                    "proj-bg",
                    "page-bg"
                )}
            >
                <div className="bg-cover">
                    <span className="bg-text">Research Projects</span>
                </div>
            </div>
            <div className='skew-box'>
                helo
            </div>
            <div className='straight-box'>
                helo
            </div>
            {loading && <Loader />}
            <div className="notes-page">
                <div className="main-page">
                    {isAuthenticated && 
                        (<AddButton caption={"Add project"} link={"/addproject"} />)
                    }
                    <div className="search-caption">Search</div>
                    <div className="search-box">
                        
                        <div className="search-input-box">
                            <img src="/svgs/search-icon.svg" alt="" width={24} className="search-icon" />
                            <input 
                                type="search" 
                                className="search-input"  
                                placeholder="Enter title of research project"
                                onChange={(e) => setKeywrd(e.target.value)}
                            />
                        </div>
                        <button 
                            className="search-button"
                            onClick={() => searchProjects(keywrd)}
                        >
                            Search
                        </button>
                    </div>
                    <div className="notes-box">
                        {projects?.map((project, key) => {
                            return (
                                <button 
                                    className="notes-unit" key={key}
                                    onClick={() => navigateTo("/viewproject",{
                                        state: {project}
                                    })}
                                >
                                    <div className="note-title">{project.title}</div>
                                    <div className="note-data">
                                        <span className="data-unit">{project.authors}</span>
                                        <span className="data-unit">
                                            {parseTime(project.createdAt)}
                                        </span>
                                        <span className="data-unit">
                                            <FontAwesomeIcon icon={faThumbsUp} className="u-icon" />
                                            <span className="note-unit-data">
                                                {project.likes.length}
                                            </span>
                                        </span>
                                        <span className="data-unit">
                                            <FontAwesomeIcon icon={faEye} className="u-icon" />
                                            <span className="note-unit-data">{project.views}</span>
                                        </span>
                                    </div>
                                </button>
                            );
                        })}
                    </div>
                    <div className="buttons-box">
                        <button 
                            className={classNames({
                                "page-button": true,
                                "disabled-button": pageNum === 1
                            })}
                            disabled={pageNum === 1}
                            onClick={() => setPageNum((p) => p - 1)}
                        >
                            Previous
                        </button>
                        <div className="page-number">{pageNum}</div>
                        <button 
                             className={classNames({
                                "page-button": true,
                                "disabled-button": projects.length === 0
                            })}
                            disabled={projects.length === 0}
                            onClick={() => setPageNum((p) => p + 1)}
                        >
                            Next
                        </button>
                    </div>
                    
                </div>
            <RightNav />
            </div>
            <AppFooter />
        </div>
    );
}