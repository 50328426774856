
import React, { StrictMode } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import env from "react-dotenv";

import { NotesPage } from "./features/notes/lecture-notes";
import { LandingPage } from "./features/landing/landing-page";
import { NotFoundPage } from "./features/landing/not-found";
import { ProjectsPage } from "./features/projects/projects";
import { QuestionsPage } from "./features/questions/questions";
import { JobsPage } from "./features/jobs/jobs";
import { LoginForm } from "./features/authentication/login";
import { SignupForm } from "./features/authentication/registration";
import { SendRecoveryEmail } from "./features/authentication/recoverymail";
import { ResetPassword } from "./features/authentication/reset-password";
import { ViewDocument } from "./features/notes/view-notes";
import { ResourcesPage } from "./features/resources/resources";
import { AuthenticationContextProvider } from "./services/authentication/authentication.context";
import ProtectedRoute from "./components/auth/private-route";
import { Dashboard } from "./features/admin/admin";
import { AnswersPage } from "./features/questions/answers";
import { AddNotesPage } from "./features/notes/add-notes";
import { AddProjectPage } from "./features/projects/add-project";
import { AddQuestion } from "./features/questions/add-question";
import { AskNoteAi } from "./features/questions/askAI";
import { MyProfilePage } from "./features/admin/profile";
import { AuthRedirect } from "./features/authentication/auth-redir";
import AdminRoute from "./components/auth/admin-route";

import "./styles/bgs.css";
import "./styles/note-header.css";
import { ViewProject } from "./features/projects/view-project";


function App() {
  return (
    <StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={env?.GOOGLE_WEB_CLIENT}>
        <AuthenticationContextProvider>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/lecture_notes" element={<NotesPage />} />
              <Route path="/research" element={<ProjectsPage />} />
              <Route path="/jobs_carreers" element={<JobsPage />} />
              <Route path="/questions" element={<QuestionsPage />} />
              <Route path="/resources" element={<ResourcesPage />} /> 
              <Route path="/login" element={<LoginForm />} />
              <Route path="/register" element={<SignupForm />} />
              <Route path="/recoveryemail" element={<SendRecoveryEmail />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/viewnotes" element={<ViewDocument />} />
              <Route path="/viewproject" element={<ViewProject />} />
              <Route path="/answers" element={<AnswersPage />} />
              <Route element={<ProtectedRoute />} >
                <Route path="/myprofile" element={<MyProfilePage />} />
                <Route element={<AdminRoute />} >
                  <Route path="/admin" element={<Dashboard />} />
                </Route>
                <Route path="/addnote" element={<AddNotesPage />} />
                <Route path="/addproject" element={<AddProjectPage />} />
                <Route path="/addquestion" element={<AddQuestion />} />
                <Route path="/asknoteai" element={<AskNoteAi />} />
                <Route path="/authredir" element={<AuthRedirect />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </AuthenticationContextProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
    </StrictMode>
  );
}


export default App;
