import  React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../components/utilities/loader";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import "../../styles/notes.css";
import "../../styles/search.css";
import "../../styles/main.css";

import { BmsHeader } from "../../components/main-components/app.header";
import { RightNav } from "../../components/main-components/right-nav";
import { getAllNotes } from "../../services/documents/documents.services";
import { parseTime } from "../../helpers/parse-time";
import { AppFooter } from "../../components/main-components/footer";
import { AddButton } from "../../components/utilities/buttons";
import { useAuth } from "../../services/authentication/authentication.context";




export const NotesPage = () => {

    const [notes, setNotes] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [loading, setLoading] = useState(false);
    const [word, setWord] = useState(null);
    const [connErr, setConnErr] = useState(null);
   

    const navigateTo = useNavigate();

    const { isAuthenticated, isLoading } = useAuth();
   
    const getAllLectureNotes = async (pageNum) => {
        setLoading(true);
        try {
            const response = await getAllNotes();
            const res = await response.json();
            console.log(res);
            const recents = res.slice((pageNum - 1) * 5, ((pageNum - 1) * 5) + 5);
            setNotes(recents);
            console.log(recents);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log({error: `fetch error: ${e}`});
            setConnErr("Unable to get lecture notes!")
        }
    }

    

    const [cat, setCat] = useState(null);

    const filterNotes = async (field) => {
        setLoading(true);
        try {
            const response = await getAllNotes();
            const res = await response.json();
            const categoryNotes = await res.filter((note) => {
                return note?.category === field;
            })
            const recents = categoryNotes.slice((pageNum - 1) * 5, ((pageNum - 1) * 5) + 5);
            setNotes(recents);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log({error: `Internal error: ${e}`});
            setConnErr("Unable to get lecture notes!")
        }
    }

    React.useEffect(() => {
        const notesType = new URLSearchParams(window.location.search).get("field");
        if (notesType) {
            setCat(notesType);
        }
        const controller = new AbortController();
        ( async() => {
            if (cat === null || cat === undefined) {
                await getAllLectureNotes(pageNum);
                return;
            }else{
                await filterNotes(cat);
            }

            console.log({notesType})
            
        })();
        ( () => controller.abort() )();
    }, [pageNum, cat]);


    const searchNotes = async (searchWord) => {
        setLoading(true);
        try {
            const response = await getAllNotes();
            const res = await response.text();
            var reg = new RegExp(searchWord.toLowerCase());
            let substr = searchWord.slice(0,3).toLowerCase();
            var subReg = new RegExp(substr);
            let results = res.filter((term) => {
                let wordStr = term.title.toLowerCase();
                
                return ( wordStr.match(reg) || wordStr.match(subReg)  ) ? term : null ;
            });
            setNotes(results);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log({error: `Internal server error: ${e}`});
        } 
    }
    
    

    return (
        <div className="page-body">
              {(loading || isLoading) && <Loader />}
            <BmsHeader />
            <div 
                className={classNames(
                    "notes-bg",
                    "page-bg"
                )}
            >
                <div className="bg-cover">
                    <span className="bg-text">Lecture Notes</span>
                </div>
            </div>
            <div className='skew-box'>
                helo
            </div>
            <div className='straight-box'>
                helo
            </div>
            <div className="notes-page">
          
            <div className="main-page">

                {isAuthenticated && 
                    (<AddButton caption={"Add notes"} link={"/addnote"} />)
                }
            
                <div className="search-caption">Search notes</div>
                <div className="search-box">
                    
                    <div className="search-input-box">
                        <img src="/svgs/search-icon.svg" alt="" width={24} className="search-icon" />
                        <input 
                            type="search" 
                            className="search-input"  
                            placeholder="Enter title of lecture notes"
                            onChange={(e) => setWord(e.target.value)}
                        />
                    </div>
                    <button 
                        className="search-button"
                        onClick={() => { searchNotes(word)}}
                    >
                        Search
                    </button>
                </div>
                <div className="notes-box">
                    {notes?.map((note) => {
                        return (
                            <button 
                                className="notes-unit" 
                                onClick={() => navigateTo("/viewnotes",{state: {notes: note}}) }
                                key={note?._id}
                            >
                                
                                <div className="note-title">
                                    <FontAwesomeIcon icon={faFilePdf} color="red" className="u-icon" />
                                    {note?.title}
                                </div>
                                <div className="note-data">
                                    <span className="data-unit">{note?.uploader?.names}</span>
                                    <span className="data-unit">
                                        {parseTime(note?.createdAt)}
                                    </span>
                                    <span className="data-unit">
                                        <FontAwesomeIcon 
                                            icon={faThumbsUp} className="u-icon" 
                                        />
                                        <span className="note-unit-data">
                                            {note?.likes.length}
                                        </span>
                                    </span>
                                    <span className="data-unit">
                                        <FontAwesomeIcon icon={faEye} className="u-icon" />
                                        <span className="note-unit-data">{note.views}</span>
                                    </span>
                                </div>
                            </button>
                        );
                    })}
                    {connErr && 
                        <div className="notes-unit">
                            <span className="note-title">{connErr}</span>
                        </div>
                    }
                </div>
                <div className="buttons-box">
                    <button 
                        className={classNames({
                            "page-button": true,
                            "disabled-button": pageNum === 1
                        })}
                        disabled={pageNum === 1}
                        onClick={() => setPageNum((p) => p - 1)}
                    >
                        Previous
                    </button>
                    <div className="page-number">{pageNum}</div>
                    <button 
                            className={classNames({
                            "page-button": true,
                            "disabled-button": notes?.length < 5
                        })}
                        disabled={false}
                        onClick={() => setPageNum((p) => p + 1)}
                    >
                        Next
                    </button>
                </div>
                
            </div>
            <RightNav />
            </div>
            <AppFooter />
        </div>
    );
}