import React, { createContext, useContext, useEffect, useState } from "react";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import env from "react-dotenv";

import { askAiQuestion, askPeerQuestion, getUserData, googleAuthPromise, sendRecoveryEmail, userAuth, userLogout, userRegistration, googleAuthentication } from "./authentication.services";


export const AuthenticationContext = createContext();

export const AuthenticationContextProvider = ({ children }) => {

    const [resStatus, setResStatus] = useState({
        authError: null,
        regError: null,
        passwordError: null,
        serverError: null,
        success: false,
        message: null
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [userData, setUserData] = useState(null);
    const [token, setToken] = useState(null);

    

    const loginUser = async (email, password) => {
        setIsLoading(true);
        try {
            const response = await userAuth(email, password);
            const res = await response.json();
            if (res.error) {
                setIsLoading(false);
                setResStatus((st) => ({...st, authError: res.error}));
                setIsAuthenticated(false);
                console.log({error: res.error});
                return;
            }
            setIsAuthenticated(true);
            setIsLoading(false);
            setResStatus((s) => ({...s, success: true}));
            setUserData(res.account);
            setToken(res.token);
            console.log(res);
            localStorage.setItem("token", res.token);
            return;
        } catch (e) {
            setResStatus((s) => ({...s, serverError: e}));
            setIsLoading(false);
            console.log({error: `Internal error on sign in: ${e}`});
        }
    }

    /** logout user */
    const logoutUser = async (token) => {
        setIsLoading(true);
        try {
            const response = await userLogout(token);
            await response.json();
            setIsAuthenticated(false);
            setIsLoading(false);
        } catch (e) {
            setResStatus((s) => ({...s, serverError: e}));
            setIsLoading(false);
            console.log({error: `Internal error on sign in: ${e}`});
        }
    }

    /** user registration */
    const registerUserAccount = async (data) => {
        setIsLoading(true);
        try {
            const response = await userRegistration(data);
            const res = await response.json();
            if (res?.error) {
                setIsLoading(false);
                setResStatus((st) => ({...st, regError: res?.error}));
                return {...resStatus, regError: res?.error };
            }
            const { account, message, token } = res;
            setResStatus((st) => ({...st, success: message, regError: null}));
            setToken(token);
            localStorage.setItem("token", token);
            setUserData(account);
            setIsLoading(false);
            return {...resStatus, success: message, regError: null}
        } catch (e) {
            setResStatus((s) => ({...s, serverError: e}));
            setIsLoading(false);
            console.log({error: `Internal error on registration: ${e}`});
        }
    }

    const authorizeUser = async (token) => {
        try {
            setIsLoading(true);
            const response = await getUserData(token);
            const res = await response.json();
            setIsLoading(false);
            if (res.error){
                setIsAuthenticated(false);
                setIsLoading(false);
                return;
            }
            setUserData(res.account);
            setIsLoading(false);
            return res;
        } catch (e) {
            setResStatus((s) => ({...s, serverError: e}));
            setIsLoading(false);
            console.log({error: `Internal error on authorization: ${e}`});
        }
    }

    /** ask question */
    const askPeerAquestion = async (token, data) => {
        setIsLoading(true);
        try {
            const response = await askPeerQuestion(token, data);
            const res = await response.json();
            setResStatus({
                success: true,
                message: res.message
            })
            setIsLoading(false);
            return res;
        } catch (e) {
            setIsLoading(false);
            setResStatus({
                serverError: e
            })
            console.log({error: e})
        }
    }

    /** send to Ai */
    const askNoteAi = async (token, question) => {
        setIsLoading(true);
        try {
            const response = await askAiQuestion(token, question);
            const res = await response.json();
            setIsLoading(false);
            return res;
        } catch (e) {
            setIsLoading(false);
            console.log({error: `Internal error: ${e}`});
        }
    }

    //send password recovery email
    const sendPassRecoveryEmail = async (email) => {
        try {
            setIsLoading(true);
            const response = await sendRecoveryEmail(email);
            const res = await response.json();
            setIsLoading(false);
            return res;
        } catch (e) {
            setIsLoading(false);
            console.log({error: `Internal error: ${e}`});
        }
    }
    
    /**************************** Google authentication ********************************** */

    const googleAuth = async (data) => {
        try {
            const response = await googleAuthentication(data);
            const res = await response.json();
            return res;
        } catch (e) {
            console.log({error: `Internal error: ${e}`});
        }
    }

    //google logout
    const googleAccountLogout = () => {
            googleLogout();
            setIsAuthenticated(false);
            localStorage.removeItem("gToken");
            localStorage.removeItem("token");
            setUserData(null);
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async (codeResponse) =>{
            const { access_token } = codeResponse;

            const response = await googleAuthPromise(access_token);
            const respData = await response.json();

            const { name, email } = respData;
            
             const authData = await googleAuth({
                name: name,
                email: email,
                token: access_token
             });
             if (authData?.newAccount) {
                localStorage.setItem("gToken", codeResponse?.access_token);
                localStorage.setItem("token", authData?.token);
                setToken(authData?.token);
                return;
             }
             setUserData(authData?.account);
             setToken(authData);
             localStorage.setItem("gToken", codeResponse?.access_token);
             localStorage.setItem("token", authData?.token);
             setIsAuthenticated(true);
             window.location.href = "/myprofile";
             console.log({authData});
        },
        onError: (error) => console.log('Login Failed:', error)
    });



    useEffect(() => {
        ( async() => {
            const getTokenAsync = new Promise((resolve, reject) => {
                const tk = localStorage.getItem("token");
                if (tk) {
                    resolve(tk);
                }else{
                    reject("No token");
                }
            }).catch((e) =>{
                console.log({error: "Token null"});
            });

            
            const tk = await getTokenAsync;
           
            setToken(tk);
            if (token) {
                const data = await authorizeUser(token);
                //const currentPath = window.location.pathname;
                if (data?.error) {
                    console.log({error: `${data?.error}`});
                    setIsAuthenticated(false);
                    return;
                }
                else if (data){ 
                    setIsAuthenticated(true);
                    setUserData(data?.account);
                }
                console.log({data, token: tk, isAuthenticated});
            }
           
        })();
        console.log({serverUrl: env?.SERVER_URL});
    }, [isAuthenticated, token]);
    return (
        <AuthenticationContext.Provider
            value={{
                userData,
                isAuthenticated,
                resStatus,
                isLoading,
                token,
                loginUser,
                logoutUser,
                registerUserAccount,
                askPeerAquestion,
                askNoteAi,
                sendPassRecoveryEmail,
                googleLogin,
                googleAccountLogout
            }}
        >
            { children }
        </AuthenticationContext.Provider>
    );
}

export const useAuth = () => useContext(AuthenticationContext);