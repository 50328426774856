import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useNavigate } from 'react-router-dom';

import "../../styles/landing.css";
import "../../styles/home.css";

import { useAuth } from '../../services/authentication/authentication.context';
import { Loader } from '../../components/utilities/loader';

import { BmsHeader } from '../../components/main-components/app.header';
import { AppFooter } from '../../components/main-components/footer';
import { categoriesData } from '../../helpers/mock-data';
const imagesArray = [
    {
        url: "/media/lecture-notes.jpg", 
        caption: "Get lecture notes, in different fields, from seasoned instructors",
        navTo: "/lecture_notes"
    },
    {
        url: "/media/research-notes.jpg",
        caption: "Have access to dissertations and peer reviewed research material",
        navTo: "/research"
    },
    {
        url: "/media/question.jpg",
        caption: "Ask your questions, which may be answered by peers, expert instructors, or our AI Assistant",
        navTo: "/questions"
    },
    {
        url: "/media/resources.jpg",
        caption: "Benefit from broad spectrum of resources, which may include PDFs, Youtube videos and other useful materials",
        navTo: "/resources"
    }
]



export const LandingPage = () => {

    const { isLoading } = useAuth();

    const navigateTo = useNavigate();

    return (
         
        <div className='page-body'>
            {isLoading && <Loader />}
            <BmsHeader />
            <div className='home-bg'></div>
            <div className='skew-box'>
                helo
            </div>
            <div className='straight-box'>
                helo
            </div>
            <div className='welcome-box'>
                <div className='welcome-section'>
                    <div className='welcome-text'>
                        <div>
                            <div className='welcome-cap'>Welcome To</div>
                            <div className='sub-cap'>Notebook for Biomedical Sciences</div>
                            <div className='welcome-sentence'>
                                Your go-to platform for medical studies and research.
                            </div>
                            <div className='welcome-sentence'>
                                We help you go seemlessly through your medical studies providing materials in the following ways:
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className='welcome-section'>
                    <img className='welcome-img' alt='' src='/media/research-notes-rbg.png' />
                </div>
            </div>
            <div className='slide-frame'>
                <div className='slide-container'>
                    <Slide
                        transitionDuration={500} 
                        duration={5000} 
                        defaultIndex={0}
                        cssClass='fade-class'
                        canSwipe
                        autoplay= {true}
                    >
                        { imagesArray.map((imageObj, indx) => {
                            return (
                                <div 
                                    key={indx}
                                    className='slide-unit'
                                >
                                    <div className='button-box'>
                                        <a href={imageObj.navTo} className='more-link'>
                                            <button className='more-button'>Get started</button>
                                        </a>
                                        <img 
                                            src={imageObj.url} 
                                            alt='' 
                                            className='slide-image' 
                                        />
                                    </div> 
                                    <div className='welcome-sentence'>
                                        <div className='slide-text'>
                                            {imageObj.caption}
                                        </div>
                                       
                                    </div>
                                    
                                </div>
                            )
                        })}
                    </Slide>
                </div>
               
            </div>
            <div className='page-sub-cap'>Lecture notes are available in the following fields:</div>
            <div className='options-div'>
                {
                    categoriesData?.map((data, indx) => {
                        return (
                            <button 
                                className='option-unit'
                                onClick={() => {
                                    navigateTo(`/lecture_notes?field=${data.category}`)
                                }}
                                key={indx}
                            >
                                
                                <img className='unit-bg' alt='' src={data.image} />
                                <span className='unit-cover'>
                                    <span className='option-name'>{data.caption}</span>
                                </span>
                            </button>
                        )
                    })
                }
            </div>
            <AppFooter />
        </div>
            
          
    );
}