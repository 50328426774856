import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../services/authentication/authentication.context";
import { formatUrl } from "../../helpers/parse-url";

import "../../styles/note-header.css";


export const BmsHeader = () => {

     const [vNav, setvNav] = useState(false);

     const navigateTo = useNavigate();

     const { isAuthenticated, userData, logoutUser, token, googleAccountLogout } = useAuth();

    return (
        <header className='note-header'>
            <div className='header-container'>
                <button 
                    className="app-home"
                    onClick={() => navigateTo("/")}
                >
                    <img className='app-logo' src='/media/notebook-logo.png' alt='' />
                    <span className='app-name'>Notebook-BMS</span>
                </button>
               
                <div
                    className={
                        classNames(
                            'note-nav',
                            {"sliding-nav": vNav}
                        )}
                >
                    <button 
                        className='note-nav-button'
                        onClick={() => navigateTo("/lecture_notes")}
                    >
                        Lecture notes
                    </button>
                    <button 
                        className='note-nav-button'
                        onClick={() => navigateTo("/research")}
                    >
                        Research
                    </button>
                    <button 
                        className='note-nav-button'
                        onClick={() => navigateTo("/questions")}
                    >
                        Questions
                    </button>
                    <button 
                        className='note-nav-button'
                        onClick={() => navigateTo("/resources")}
                    >
                        Resources
                    </button>
                    { isAuthenticated ?
                        <button 
                            className='auth-button'
                            onClick={() => {
                                if (userData?.authMethod === "Google"){
                                    logoutUser(token);
                                    googleAccountLogout();
                                }else logoutUser(token);
                            }}
                        >
                            logout
                        </button>:
                         <button 
                         className='auth-button'
                            onClick={() => navigateTo("/login")}
                        >
                            sign in
                        </button>
                   
                    }
                    { !isAuthenticated  ?
                    <button 
                        className='auth-button'
                        onClick={() => navigateTo("/register")}
                    >
                        register
                    </button> :
                    <button 
                        className="user-avatar-box"
                        onClick={() => navigateTo("/myprofile")}
                    >
                        <img 
                            src={formatUrl(userData?.avatar)}
                            className="user-img" 
                            alt=""
                        />
                    </button>
                    }
                </div>
            </div>
            <button
                className='bar-close'
                onClick={() => {
                    setvNav((v) => !v)
                }}
            >
                <FontAwesomeIcon icon={
                    vNav ? faClose : faBars
                } />
            </button>
        </header>
    )
}