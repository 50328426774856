import React, {  useState } from "react"; 
import { faThumbsUp as ThumbsUpO } from "@fortawesome/free-regular-svg-icons";
import { faDownload, faThumbsUp as ThumbsUpS } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RightNav } from "../../components/main-components/right-nav";
import { formatUrl } from "../../helpers/parse-url";
import { useLocation } from "react-router-dom";

import "../../styles/view-doc.css";
import "../../styles/notes.css";
import "../../styles/search.css";
import "../../styles/main.css";
import { BmsHeader } from "../../components/main-components/app.header";


export const ViewProject = () => {

    const [liked, setLiked] = useState(false);
    
    
    const project = useLocation().state?.project;

    return (
        <div className="page-body">
            <BmsHeader />
            <div className='straight-box'>
                helo
            </div>
            <div className='straight-box'>
                helo
            </div>
            <div className="notes-page">
                <div className ="main-page">
                <div className ="notes_view_box">
                <object 
                    className="pdf-view"
                    type="application/pdf"
                    data={formatUrl(project?.document)}
                    width="100%"
                    height="100%"
                    onLoadedData={() => alert("completed")}
                >
                    <div className="loading -text">Pdf loading...</div>
                    <a href={formatUrl(project?.document)} >
                        {document?.title}
                        <button className="download-pdf">
                            <FontAwesomeIcon icon={faDownload} />
                        </button>
                    </a>
                    
                </object>

               
            
                <sapn className ="mob-load" >
                    <span className ="descr">No preview available for mobile</span>
                    <a className ="down-link" href={formatUrl(project?.document)} >
                    Click here to download 
                    <FontAwesomeIcon icon={faDownload} />
                    </a>
                </sapn>
                </div>
                <div className ="left-center">
                    <div className ="like-status">Like&nbsp;&nbsp;</div>
                    <button className ="like-btn" onClick={() => setLiked((v) => (!v))}>
                    {!liked.length ?
                        <FontAwesomeIcon icon={ThumbsUpO} className="unlike"/> :
                        <FontAwesomeIcon icon={ThumbsUpS} className="unlike"/>
                    }
                        <i className ="fa-regular fa-thumbs-up like"></i>
                    </button>
                </div>
                <div className ="like-err"></div>
                <div className ="comments">
                    <div className ="comment-caption">Add a comment</div>
                    <form  className ="add-comment">
                        <div className ="comment-box">
                            <textarea placeholder="Type your comments here" className ="comment-area" name="foot_notes" id="footnote" cols="30" rows="7" required></textarea>
                        </div>
                        <div className ="button_box">
                            <button className ="submit_doc" id="add-comment">
                                <span className ="upload_icon"><i className ="fa fa-commenting-o"></i></span>
                                Post comment
                            </button>
                        </div>
                    </form>
                </div> 
                <div className ="see-comments">
                    <div className ="comment-caption">Comments</div>
                    
                </div>
            </div>
            <RightNav />
        </div>
        </div>
    );
}